import React from "react"
import { graphql, Link } from "gatsby"

import kebabCase from "lodash/kebabCase"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import colors from "../components/colors"
import SEO from "../components/seo"

const breakpoints = [600, 700, 1000]

const mq = breakpoints.map(
  bp => `@media (min-width: ${bp}px)`
)

const Wrapper = styled.article`
  display: flex;
  position: sticky;
  padding: 0 5%;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  border-style: solid;
  border-width: 1px;
  border-color: grey;
  background-color: ${colors.lightyellow};
  box-shadow: -20px 20px ${colors.navy};
  max-width: 100%;
  position: sticky;
  z-index: 2;
`

const headerImage = css`
  height: 100%;
  width: 100%;
  margin-top: 1rem;
`

const UnderImage = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  ${[mq[0]]} {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  ${mq[1]} {
    padding-left: 4rem;
    padding-right: 4rem;
  }
`

const Title = styled.h1`
  padding-top: 2rem;
  margin: 0 0;
`

const AuthorDate = styled.h6`
  line-height: 1.5;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin: 0 0;
  font-family: Noto Sans;
`

const Text = styled.div`
  margin: 0 0;
`

const Tags = styled.div`
  font-family: Noto Sans;
  font-size: 90%;
`

export default function Template({ data }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  const siteTitle = " | Groove Café"
  const name = frontmatter.title.concat(siteTitle)

  return (
    <Layout>
      <SEO
        title={name}
        image={frontmatter.featuredImage.childImageSharp.fluid}
        path={frontmatter.path}
      />
      <Wrapper>
        <Box>
          <Img css={headerImage} fluid={frontmatter.featuredImage.childImageSharp.fluid} />
          <UnderImage>
            <Title>{frontmatter.title}</Title>
            <AuthorDate>
              {frontmatter.date}
              <br />
              by {frontmatter.author}
            </AuthorDate>
            <Text
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
            <Tags>
              Tags:&nbsp;
              {frontmatter.tags.map((tag, i) => (
                <Link to={`/tags/${kebabCase(tag)}/`}>
                  {tag}
                  {i < frontmatter.tags.length - 1 ? ", " : ""}
                </Link>
              ))}
            </Tags>
          </UnderImage>
        </Box>
      </Wrapper>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt(pruneLength: 160)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        tags
        author
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
